import { Controller } from "stimulus"


export default class extends Controller {
  initialize() {
    this.element.addEventListener('change', event => {
      let selectedOption = this.element.options[this.element.selectedIndex]

      window.location = selectedOption.getAttribute('data-url')
    });
  }
}
