import React, { useState, useEffect, useRef } from 'react';

const Dropdown = props => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const buttonRef = useRef(null);

  useEffect(() => setHeight(buttonRef.current.clientHeight));

  return (
    <>
      <div className="ml-2 border rounded text-2xl flex items-center cursor-pointer" onClick={() => setOpen(!open) } ref={buttonRef}>
        <ion-icon name="ellipsis-vertical-outline" />
      </div>
      {open && <div className="dropdown-content" style={ {transform: `translate3d(0px, ${height+2}px, 0px)`, willChange: 'transform'} }>
        {props.links.map(link =>
          <a href={link.url} key={link.url}>{link.title}</a>
        )}
        </div>
      }
    </>
  )
}

export default Dropdown
