import $ from 'jquery'
import select2 from 'select2'
import 'select2/dist/css/select2.css'

import '../css/tailwind_base.css'
import '../css/main.js'
import '../css/tailwind_rest.css'

import './qr_card'

import '../controllers'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener('turbolinks:load', function() {
  $('select.select2').select2({tags: true, width: '100%'});
});
