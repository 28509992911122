document.addEventListener('turbolinks:load', function() {
  var maxTextWidth = 42;
  var nameElements = document.getElementsByClassName('scalablePetName');

  for (let nameElement of nameElements) {
    var scaledFontSize = maxTextWidth / nameElement.getComputedTextLength() * 5;
    
    if(scaledFontSize > 15) scaledFontSize = 15;

    nameElement.style.fontSize = scaledFontSize;
  }
});
