import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))

// Flatpickr
import Flatpickr from 'stimulus-flatpickr'
require("flatpickr/dist/flatpickr.css")
application.register('flatpickr', Flatpickr)
